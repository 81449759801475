var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.fetchingData)?_c('div',[_c('table-skeleton')],1):[_c('v-banner',{staticClass:"banner mb-4",attrs:{"single-line":""}},[_c('div',{staticClass:"d-flex align-start"},[_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{attrs:{"size":"24"}},[_vm._v(" mdi-information-outline ")]),_c('p',{staticClass:"ma-0 ml-4"},[_vm._v(" "+_vm._s(_vm.licensingDataNotice)+" ")])],1)])]),_c('v-data-table',{staticClass:"d-flex flex-column justify-space-between",attrs:{"flat":"","calculate-widths":"","hide-default-footer":"","headers":_vm.licenseSummaryColumnHeaders,"items":_vm.licenseSummaryData},scopedSlots:_vm._u([{key:"item.term_start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.term_start))+" ")]}},{key:"item.term_end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.term_end))+" ")]}},{key:"item.used",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{
            'licensing-success': item.percent_remaining > 30,
            'licensing-warning':
              item.percent_remaining <= 30 && item.percent_remaining > 10,
            'licensing-error': item.percent_remaining <= 10
          }},[_vm._v(" "+_vm._s(item.used)+" ")])]}}],null,true)})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }