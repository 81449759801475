













import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class TableSkeleton extends Vue {
  @Prop({ default: true }) withActions?: boolean;
}
