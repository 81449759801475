

















































import { Vue, Component, Watch } from "vue-property-decorator";
import TableSkeleton from "@/components/skeletons/TableSkeleton.vue";
import UserStore from "@/store/user.store";
import { ModalTableHeader } from "@/models/document-entry/modal-tables";
import { LicensingSummaryData } from "@/models/reporting-analytics/licensing-summary-data";
import { Customer } from "@/models/user/customer";
import { licensingService } from "@/services/licensing.service";
import { utils } from "@/utils/okta-utils";
import { getModule } from "vuex-module-decorators";

@Component({
  components: {
    "table-skeleton": TableSkeleton
  }
})
export default class Licensing extends Vue {
  private fetchingData = false;
  private licensingDataNotice =
    "Licensing metrics are calculated daily and do not reflect real-time updates";
  private licenseSummaryColumnHeaders: ModalTableHeader[] = [
    {
      text: "Term Start",
      align: "left",
      sortable: false,
      value: "term_start"
    },
    {
      text: "Term End",
      align: "left",
      sortable: false,
      value: "term_end"
    },
    {
      text: "Unit",
      align: "left",
      sortable: false,
      value: "unit_measure"
    },
    {
      text: "Purchased",
      align: "right",
      sortable: false,
      value: "purchased"
    },
    {
      text: "Used",
      align: "right",
      sortable: false,
      value: "used"
    }
  ];
  private licenseSummaryData: LicensingSummaryData[] = [];

  private userStore = getModule(UserStore, this.$store);

  private get selectedCustomer(): Customer {
    return this.userStore.getSelectedCustomer;
  }

  async created() {
    await this.loadData();
  }

  async loadData() {
    this.fetchingData = true;
    try {
      this.licenseSummaryData = await licensingService.getLicensingSummary();
    } catch (error) {
      this.licenseSummaryData = [];
    }
    this.fetchingData = false;
  }

  private formatDate(item: Date) {
    return utils.formatDateWithoutTimezoneConversion(item, "L");
  }

  @Watch("selectedCustomer", { deep: true })
  async onCustomerChange() {
    await this.loadData();
  }
}
