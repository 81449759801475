import moment from "moment";

class Util {
  urlParams(query: string) {
    const urlParams: any = {};
    const search = /([^&=]+)=?([^&]*)/g;

    let match;
    while ((match = search.exec(query)) != null) {
      urlParams[match[1]] = match[2];
    }

    return urlParams;
  }

  offsetDate(date: string, format?: string) {
    if (!date || date.length <= 0) return "";
    const pattern = format ? format : "MM/DD/YYYY HH:mm:ss";

    return moment(moment.utc(date).toDate())
      .local()
      .format(pattern);
  }

  utcToLocalDateString(date: Date, format?: string) {
    if (!date) return "";
    const pattern = format ? format : "L LT";
    const locale = Intl.DateTimeFormat().resolvedOptions().locale;

    moment.locale(locale.toLowerCase());
    return moment
      .utc(date)
      .local()
      .format(pattern);
  }

  formatDateWithoutTimezoneConversion(date: Date, format?: string) {
    if (!date) return "";
    const pattern = format ? format : "L LT";
    const locale = Intl.DateTimeFormat().resolvedOptions().locale;

    moment.locale(locale.toLowerCase());
    return moment(date).format(pattern);
  }

  formatStringToDateWithFormat(date: string, format?: string) {
    if (!date) return "";
    const pattern = format ? format : "L LT";
    return moment(date).format(pattern);
  }
}

export const utils = Object.freeze(new Util()); // singleton
