import { app } from "@/main";
import { LicensingSummaryData } from "@/models/reporting-analytics/licensing-summary-data";

class LicensingService {
  public async getLicensingSummary(): Promise<LicensingSummaryData[]> {
    const apiResponse: any = await app.$ravenapi.get(
      "api/analytics/licensingsummary"
    );
    return apiResponse;
  }
}

export const licensingService = new LicensingService();
